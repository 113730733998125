import {useState, useEffect} from 'react';
import axios from 'axios';
import {apiRequest} from '../utils/apiRequests';
import React, {useRef} from 'react';
import Select from 'react-select';

type Country = {
  name: {
    common: string;
  };
  flag: '';
  flags: {
    png: string;
  };
  cca2: string;
};

type City = {
  city: string;
  price: number;
};

type CountryFlightData = {
  country_flight_cities: City[];
  id: number;
  country: string;
  flag: string;
  country_flight_cities_attributes: City[];
};

export function AdminCities() {
  const [countries, setCountries] = useState<Country[]>([]); // Store countries
  const [newCountry, setNewCountry] = useState({
    flag: '',
    country: '',
    country_flight_cities_attributes: [{city: '', price: 0}],
  });

  const searchInputRef = useRef<HTMLInputElement>(null);

  const [cities, setCities] = useState<string[]>([]); // Make sure this is initialized as an empty array
  const [countryData, setCountryData] = useState<CountryFlightData[]>([]); // Store country flight data for table display
  const [editing, setEditing] = useState<boolean>(false); // To determine if we are editing a country or adding a new one
  const [selectedCountryId, setSelectedCountryId] = useState<number | null>(
    null,
  ); // To store the id of the country being edited

  // Fetch countries for the dropdown list
  useEffect(() => {
    async function fetchCountries() {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data: Country[] = await response.json();
        const updatedCountries = data.slice(1); // Remove the first country

        setCountries(updatedCountries); // Set the updated list of countries
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    }

    fetchCountries();
  }, []);

  async function fetchCountryData() {
    try {
      const response = await axios.get<CountryFlightData[]>(
        'https://dream-travels-backend-9eaa9bf82980.herokuapp.com/api/v1/country_flight_data',
      );
      setCountryData(response.data);
    } catch (error) {
      console.error('Error fetching country data:', error);
    }
  }

  useEffect(() => {
    fetchCountryData();
  }, []);

  // Fetch cities by country
  const fetchCitiesByCountry = async (countryName: string) => {
    try {
      const response = await axios.post(
        'https://countriesnow.space/api/v0.1/countries/cities',
        {country: countryName},
      );

      // Ensure the response data is an array
      const cityData = Array.isArray(response.data?.data)
        ? response.data.data
        : [];

      setCities(cityData); // Update the cities state
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCountryChange = (selectedOption: any) => {
    if (!selectedOption) return;

    const {value: countryName, flag: countryFlag} = selectedOption;

    fetchCitiesByCountry(countryName);

    setNewCountry({
      flag: countryFlag,
      country: countryName,
      country_flight_cities_attributes: [{city: '', price: 0}],
    });
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const index = Number(e.target.getAttribute('data-index'));
    const {value} = e.target;

    setNewCountry(prev => {
      const updatedCities = [...prev.country_flight_cities_attributes];
      updatedCities[index] = {...updatedCities[index], city: value};
      return {...prev, country_flight_cities_attributes: updatedCities};
    });
  };

  const handlePriceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const {value} = e.target;

    setNewCountry(prev => {
      const updatedCities = [...prev.country_flight_cities_attributes];
      updatedCities[index] = {
        ...updatedCities[index],
        price: Number(value),
      };
      return {...prev, country_flight_cities_attributes: updatedCities};
    });
  };
  const addCityField = () => {
    setNewCountry(prev => ({
      ...prev,
      country_flight_cities_attributes: [
        ...prev.country_flight_cities_attributes,
        {city: '', price: 0},
      ],
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setNewCountry({
      flag: '',
      country: '',
      country_flight_cities_attributes: [{city: '', price: 0}],
    });

    const body = JSON.stringify({country_flight_data: newCountry});

    // Check if editing existing country or adding new country
    if (editing && selectedCountryId !== null) {
      await apiRequest(
        `https://dream-travels-backend-9eaa9bf82980.herokuapp.com/api/v1/country_flight_data/${selectedCountryId}`,
        body,
        'PUT',
      );
      alert('Country has been updated');
    } else {
      await apiRequest(
        'https://dream-travels-backend-9eaa9bf82980.herokuapp.com/api/v1/country_flight_data',
        body,
        'POST',
      );
      alert('Country has been added');
    }

    setEditing(false); // Reset editing flag after submission
    fetchCountryData();
  };

  const handleEdit = (countryId: number) => {
    // Find the country by matching the countryId
    const selectedCountryData = countryData.find(item => item.id === countryId);

    if (selectedCountryData) {
      // Set the details for new country (optional: depends on how y``ou want to manage the data)
      setNewCountry({
        flag: selectedCountryData.flag,
        country: selectedCountryData.country,
        country_flight_cities_attributes:
          selectedCountryData.country_flight_cities,
      });

      setSelectedCountryId(countryId);
      setEditing(true); // Switch to editing mode
    }
  };

  const handleDelete = async (countryId: number) => {
    try {
      await apiRequest(
        `https://dream-travels-backend-9eaa9bf82980.herokuapp.com/api/v1/country_flight_data/${countryId}`,
        '',
        'DELETE',
      );
      setCountryData(countryData.filter(item => item.id !== countryId));
    } catch (error) {
      console.error('Error deleting country:', error);
    }
  };

  const handleSearch = () => {
    const searchTerm = searchInputRef.current?.value.toLowerCase() || '';

    if (searchTerm != '') {
      const filteredCountries = countryData.filter(data =>
        data.country?.toLowerCase().includes(searchTerm),
      );

      // Update countryData with the filtered results
      setCountryData(filteredCountries);
    } else {
      fetchCountryData();
    }
  };

  const countryOptions = countries.map(country => ({
    value: country.name.common,
    label: (
      <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
        <img
          src={country.flags.png}
          alt={country.name.common}
          style={{width: '20px', height: '15px'}}
        />
        {country.name.common}
      </div>
    ),
    flag: country.flags.png,
  }));

  return (
    <div>
      <div>
        <div className="is-8-tablet is-6-desktop is-5-widescreen">
          <div className="box has-background-light has-shadow-md">
            <h2 className="title is-3 has-text-weight-bold has-text-dark">
              {editing ? 'Edit Country' : 'Country Ads'}
            </h2>

            <form onSubmit={handleSubmit} className="mt-5">
              <div className="field">
                <label className="label has-text-grey-dark">Country</label>
                <div className="control">
                  <Select
                    options={countryOptions}
                    onChange={handleCountryChange}
                    isClearable
                    placeholder="Select Country"
                    classNamePrefix="react-select"
                  />
                </div>
              </div>

              <div>
                {newCountry.country_flight_cities_attributes.map(
                  (cityObj, index) => (
                    <div key={index} className="field is-horizontal mb-4">
                      <div className="field-body">
                        <div className="field is-expanded">
                          <label className="label has-text-grey-dark">
                            City
                          </label>
                          <div className="control select is-fullwidth has-background-white">
                            <select
                              name="city"
                              value={cityObj.city}
                              onChange={handleCityChange}
                              data-index={index}
                              className="is-white"
                              style={{backgroundColor: 'white', color: 'black'}}
                              required>
                              <option value="">Select City</option>
                              {cities.map((city, cityIndex) => (
                                <option key={cityIndex} value={city}>
                                  {city}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="field is-expanded">
                          <label className="label has-text-grey-dark">
                            Price
                          </label>
                          <div className="control">
                            <input
                              type="number"
                              name="price"
                              value={cityObj.price}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => handlePriceChange(e, index)}
                              className="input has-background-white"
                              placeholder="Enter price"
                              min="0"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                )}

                <div className="control">
                  <button
                    type="button"
                    onClick={addCityField}
                    className="button mt-3">
                    <span>Add City</span>
                  </button>
                </div>
              </div>

              <div className="field mt-5">
                <div className="control">
                  <button type="submit" className="button ">
                    <span>{editing ? 'Update' : 'Submit'}</span>
                  </button>
                </div>
              </div>
            </form>
          </div>

          {/* Search Input */}
          <div className="field">
            <label className="label">Search Country</label>
            <div className="control">
              <input
                type="text"
                className="input"
                ref={searchInputRef}
                onInput={handleSearch}
                placeholder="Search by country"
              />
            </div>
          </div>

          {/* Table to display country flight data */}
          <table>
            <thead>
              <tr>
                <th>Country</th>
                <th>Flag</th>
                <th>City</th>
                <th>Price</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {countryData.map(data => (
                <tr key={data.id}>
                  <td>{data.country}</td>
                  <td>
                    <img
                      src={data.flag}
                      alt={`Flag of ${data.country}`}
                      width="30"
                    />
                  </td>
                  <td>
                    {/* Check if country_flight_cities_attributes is defined */}
                    {data.country_flight_cities &&
                    data.country_flight_cities.length > 0 ? (
                      data.country_flight_cities.map((city, index) => (
                        <div key={index}>{city.city}</div>
                      ))
                    ) : (
                      <span>No cities available</span>
                    )}
                  </td>
                  <td>
                    {/* Display the cities and prices again */}
                    {data.country_flight_cities &&
                    data.country_flight_cities.length > 0 ? (
                      data.country_flight_cities.map((city, index) => (
                        <div key={index}>${city.price}</div>
                      ))
                    ) : (
                      <span>No cities available</span>
                    )}
                  </td>
                  <td>
                    {/* Edit Button */}
                    <button
                      onClick={() => handleEdit(data.id)}
                      className="button is-info is-small">
                      Edit
                    </button>
                    {/* Delete Button */}
                    <button
                      onClick={() => handleDelete(data.id)}
                      className="button is-danger is-small ml-2">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
