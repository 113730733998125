import React, {useState} from 'react';
import * as XLSX from 'xlsx';
import './admin-styles/uploadFile.css';

export function AdminFileUpload() {
  const [file, setFile] = useState<File | null>(null);
  const [secondFile, setSecondFile] = useState<File | null>(null);
  const [error, setError] = useState<string>('');
  const [secondError, setSecondError] = useState<string>('');
  const [uploading, setUploading] = useState<string | null>('null');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      if (
        selectedFile.type === 'text/csv' ||
        selectedFile.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        setFile(selectedFile);
        setError('');
      } else {
        setError('Only CSV or Excel files are allowed.');
        setFile(null);
      }
    }
  };

  const handleSecondFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      if (
        selectedFile.type === 'text/csv' ||
        selectedFile.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        setSecondFile(selectedFile);
        setSecondError('');
      } else {
        setSecondError('Only CSV or Excel files are allowed.');
        setSecondFile(null);
      }
    }
  };

  const handleFileUpload = (
    fileToUpload: File | null,
    isSecondFile: boolean,
  ) => {
    if (!fileToUpload) {
      if (isSecondFile) {
        setSecondError('Please select a file before uploading.');
      } else {
        setError('Please select a file before uploading.');
      }
      return;
    }

    const reader = new FileReader();
    reader.onload = e => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, {type: 'array'});

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(sheet);

      sendToBackend(json, isSecondFile);
    };
    reader.readAsArrayBuffer(fileToUpload);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sendToBackend = async (data: any, isSecondFile: boolean) => {
    setUploading(isSecondFile ? 'two-way' : 'one-way');
    try {
      await fetch(
        isSecondFile
          ? 'https://dream-travels-backend-9eaa9bf82980.herokuapp.com/api/v1/two_way_flights'
          : 'https://dream-travels-backend-9eaa9bf82980.herokuapp.com/api/v1/one_way_flights',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            [isSecondFile ? 'two_way_flights' : 'one_way_flights']: data,
          }),
        },
      );
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setUploading(null);
      alert('File Has Been Uploaded Successfully!');
      location.reload();
    }
  };

  return (
    <div className="file-uploader-section">
      <div className="file-uploader">
        <h2 className="title is-3 has-text-weight-bold has-text-dark">
          Upload One Way CSV or Excel File
        </h2>
        <div className="upload-container">
          <label htmlFor="file-upload" className="file-label">
            Choose File
          </label>
          <input
            id="file-upload"
            type="file"
            accept=".csv, .xlsx"
            onChange={handleFileChange}
            className="file-input"
          />
          {file && (
            <p className="file-info has-text-weight-bold">
              Selected: {file.name}
            </p>
          )}
          {error && (
            <p className="error-message has-text-weight-bold">{error}</p>
          )}
          <button
            onClick={() => handleFileUpload(file, false)}
            className="button is-primary"
            disabled={uploading === 'one-way'}>
            {uploading === 'one-way' ? 'Uploading...' : 'Upload File'}
          </button>
        </div>
      </div>
      <div className="file-uploader">
        <h2 className="title is-3 has-text-weight-bold has-text-dark">
          Upload Two Way CSV or Excel File
        </h2>
        <div className="upload-container">
          <label htmlFor="second-file-upload" className="file-label">
            Choose File
          </label>
          <input
            id="second-file-upload"
            type="file"
            accept=".csv, .xlsx"
            onChange={handleSecondFileChange}
            className="file-input"
          />
          {secondFile && (
            <p className="file-info has-text-weight-bold">
              Selected: {secondFile.name}
            </p>
          )}
          {secondError && (
            <p className="error-message has-text-weight-bold">{secondError}</p>
          )}
          <button
            onClick={() => handleFileUpload(secondFile, true)}
            className="button is-primary"
            disabled={uploading === 'two-way'}>
            {uploading === 'two-way' ? 'Uploading...' : 'Upload File'}
          </button>
        </div>
      </div>
    </div>
  );
}
