import React, {ReactElement, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

interface PrivateRouteProps {
  children: ReactElement;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({children}) => {
  const isAdmin = localStorage.getItem('userRole') === 'admin';
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin) {
      navigate('/');
    }
  }, [isAdmin, navigate]);

  return isAdmin ? children : null;
};
