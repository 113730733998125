import {useEffect, useState} from 'react';
import './admin-styles/adminDashboard.css';

const initialState = [
  {
    best_time: '',
    created_at: '',
    customer_name: '',
    email: '',
    id: 1,
    instructions: '',
    phone: '',
    updated_at: '',
  },
];

export function AdminDashboard() {
  const [users, setUsers] = useState(initialState);

  async function apiRequest() {
    try {
      const response = await fetch(
        'https://dream-travels-backend-9eaa9bf82980.herokuapp.com/api/v1/callback_requests',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      setUsers(await response.json());

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    apiRequest();
  }, []);

  return (
    <div>
      <div className="container">
        <h1 className="admin-heading">Call Back Requests</h1>
        <table>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Best Time</th>
            <th>Instructions</th>
            <th>Created at</th>
            <th>Updated at</th>
          </tr>
          {users.map(
            (
              user: {
                best_time: string;
                created_at: string;
                customer_name: string;
                email: string;
                id: number;
                instructions: string;
                phone: string;
                updated_at: string;
              },
              index: number,
            ) => {
              return (
                <tr key={index}>
                  <td>{user.id}</td>
                  <td>{user.customer_name}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>{user.best_time}</td>
                  <td>{user.instructions}</td>
                  <td>{user.created_at}</td>
                  <td>{user.updated_at}</td>
                </tr>
              );
            },
          )}
        </table>
      </div>
    </div>
  );
}
