/* eslint-disable @typescript-eslint/no-explicit-any */
import {useState, useReducer, useEffect, useRef} from 'react';
import '../styles/header.css';
import {useNavigate, useParams} from 'react-router-dom';
import {
  FaPlane,
  FaPhoneAlt,
  FaBed,
  FaPlaneArrival,
  FaPlaneDeparture,
} from 'react-icons/fa';

import bg1 from '../assets/header/bg1.jpg';
import bg2 from '../assets/header/bg2.jpg';
import bg3 from '../assets/header/bg3.jpg';

// Define types for the form state
interface Flight {
  from: string;
  to: string;
  cabinClass: string;
  departure: string;
  returnDate?: string;
  adults: number;
  children: number;
  infants: number;
}

type TripType = 'round-trip' | 'one-way' | 'multi-city';

interface FormState {
  tripType: TripType;
  flights: Flight[];
  name: string;
  email: string;
  phone: string;
}

type FormAction =
  | {type: 'SET_TRIP_TYPE'; payload: TripType}
  | {type: 'SET_FLIGHT'; index: number; payload: Partial<Flight>}
  | {type: 'ADD_FLIGHT'}
  | {type: 'SET_FIELD'; field: keyof FormState; payload: unknown};

// Initial state
const initialState: FormState = {
  tripType: 'round-trip',
  flights: [
    {
      from: '',
      to: '',
      cabinClass: 'economy',
      departure: '',
      returnDate: '',
      adults: 1,
      children: 0,
      infants: 0,
    },
  ],
  name: '',
  email: '',
  phone: '',
};

// Reducer function
const flightReducer = (state: FormState, action: FormAction): FormState => {
  switch (action.type) {
    case 'SET_TRIP_TYPE': {
      return {...state, tripType: action.payload};
    }

    case 'SET_FLIGHT': {
      const updatedFlights: Flight[] = [...state.flights];
      updatedFlights[action.index] = {
        ...updatedFlights[action.index],
        ...action.payload,
      };
      return {...state, flights: updatedFlights};
    }

    case 'ADD_FLIGHT': {
      return {
        ...state,
        flights: [
          ...state.flights,
          {
            from: '',
            to: '',
            cabinClass: 'economy',
            departure: '',
            returnDate: '',
            adults: 1,
            children: 0,
            infants: 0,
          },
        ],
      };
    }

    case 'SET_FIELD': {
      return {...state, [action.field]: action.payload};
    }

    default:
      return state;
  }
};
type FlightCity = {
  city: string;
  price: string;
};

type CountryData = {
  flag: string;
  country: string;
  country_flight_cities: FlightCity[];
};

type Cities = {
  id: string;
  type: string;
  attributes: {
    cities_name: string;
    city_name: string;
    iata_code: string;
  };
};

type CitiesArrData = {
  data: Cities[];
};

export function Header() {
  const navigate = useNavigate();
  const {city} = useParams();

  const [activeTab, setActiveTab] = useState('flight');
  const [currentBgIndex, setCurrentBgIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [suggestedCities, setSuggestedCities] = useState<{
    from: Cities[];
    to: Cities[];
  }>({
    from: [],
    to: [],
  });
  const [state, dispatch] = useReducer(flightReducer, initialState);
  const [countryData, setCountryData] = useState<CountryData[]>([
    {
      flag: '',
      country: '',
      country_flight_cities: [{city: '', price: ''}],
    },
  ]);
  const backgrounds = [bg1, bg2, bg3];
  const citiesArrData = useRef<CitiesArrData>({data: []});
  const suggestionsRef = useRef<HTMLDivElement | null>(null);

  const handleActiveTab = function (text: string) {
    setActiveTab(text);
  };

  const handleTripTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({type: 'SET_TRIP_TYPE', payload: e.target.id as TripType});
  };

  const handleFlightChange = (
    index: number,
    field: keyof Flight,
    value: string,
  ) => {
    dispatch({type: 'SET_FLIGHT', index, payload: {[field]: value}});

    if (value) {
      const filtered = citiesArrData.current.data.filter(city =>
        city.attributes.cities_name.toLowerCase().includes(value.toLowerCase()),
      );

      setSuggestedCities({
        from: field === 'from' ? filtered : [],
        to: field === 'to' ? filtered : [],
      });
    } else {
      setSuggestedCities({
        from: field === 'from' ? [] : suggestedCities.from,
        to: field === 'to' ? [] : suggestedCities.to,
      });
    }
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    dispatch({
      type: 'SET_FIELD',
      field: e.target.id as keyof FormState,
      payload: e.target.value,
    });
  };

  const handleSuggestionClick = (
    index: number,
    field: keyof Flight,
    cityName: string,
  ) => {
    dispatch({type: 'SET_FLIGHT', index, payload: {[field]: cityName}});

    setSuggestedCities({
      from: field === 'from' ? [] : suggestedCities.from,
      to: field === 'to' ? [] : suggestedCities.to,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const {tripType, flights} = state;

      const response = await fetch(
        tripType === 'round-trip'
          ? `https://dream-travels-backend-9eaa9bf82980.herokuapp.com/api/v1/two_way_flights/search?departure=${flights[0].from.split(' - ').pop()}&arrival=${flights[0].to.split(' - ').pop()}`
          : `https://dream-travels-backend-9eaa9bf82980.herokuapp.com/api/v1/one_way_flights/search?departure=${flights[0].from.split(' - ').pop()}&arrival=${flights[0].to.split(' - ').pop()}`,
      );

      if (!response.ok) {
        navigate('/request-callback');
      }

      const data = await response.json();

      // Pass data via navigate state
      navigate(`/tickets?${JSON.stringify(flights)}`, {state: {data}});
    } catch (error) {
      console.error('Error fetching flights:', error);
      alert('Error fetching flights. Please try again.');
    }
  };
  async function apiRequest() {
    try {
      const response = await fetch(
        'https://dream-travels-backend-9eaa9bf82980.herokuapp.com/api/v1/country_flight_data',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();

      const transformedData = data.map((item: any) => ({
        flag: item?.flag,
        country: item?.country,
        country_flight_cities: item?.country_flight_cities.map(
          (cityData: any) => ({
            city: cityData.city,
            price: cityData.price,
          }),
        ),
      }));

      setCountryData(transformedData);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function getCities() {
    try {
      const citiesArr = await fetch(
        'https://dream-travels-backend-9eaa9bf82980.herokuapp.com/api/v1/cities',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (!citiesArr.ok) {
        throw new Error(`Error: ${citiesArr.status}`);
      }

      citiesArrData.current = await citiesArr.json();
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    apiRequest();
  }, []);

  useEffect(() => {
    getCities();
  }, [citiesArrData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % countryData.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [countryData.length]);

  useEffect(() => {
    dispatch({
      type: 'SET_FLIGHT',
      index: 0,
      payload: {to: city || '', from: 'London Heathrow - LHR'},
    });
  }, [city]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBgIndex(prevIndex => (prevIndex + 1) % backgrounds.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target as Node)
      ) {
        setTimeout(() => {
          setSuggestedCities({
            from: [],
            to: [],
          });
        }, 100);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header
      className="page-header"
      style={{
        background: `url('${backgrounds[currentBgIndex]}')`,
      }}>
      <div className="container">
        <section className="header-form-container">
          <div className="flight-form-btns-container">
            <button
              className={`flight-form-btn ${activeTab === 'flight' ? 'active-tab' : ''}`}
              onClick={() => handleActiveTab('flight')}
              type="button">
              <FaPlane /> <span>Flights</span>
            </button>
            <button
              className={`flight-form-btn ${activeTab === 'hotel' ? 'active-tab' : ''}`}
              onClick={() => handleActiveTab('hotel')}
              type="button">
              <FaBed />
              <span>Hotels</span>
            </button>
          </div>
          {activeTab === 'flight' ? (
            <form
              action="submit"
              className="header-form"
              onSubmit={handleSubmit}>
              <div className="trip-details">
                <input
                  type="radio"
                  id="round-trip"
                  name="flight-details"
                  checked={state.tripType === 'round-trip'}
                  onChange={e => handleTripTypeChange(e)}
                />
                <label htmlFor="round-trip">Round Trip</label>
                <input
                  type="radio"
                  id="one-way"
                  name="flight-details"
                  checked={state.tripType === 'one-way'}
                  onChange={e => handleTripTypeChange(e)}
                />
                <label htmlFor="one-way">One Way</label>
                <input
                  type="radio"
                  id="multi-city"
                  name="flight-details"
                  checked={state.tripType === 'multi-city'}
                  onChange={e => handleTripTypeChange(e)}
                />
                <label htmlFor="multi-city">Multi-city</label>
              </div>

              {state.flights.map((flight, index) => (
                <div key={index}>
                  <div key={index} className="flex-column gap-2">
                    <div key={index} className="flight-route flex-row gap-2">
                      <div className="flex-column from">
                        {state.tripType === 'multi-city' && <p>1st LEG</p>}
                        <label htmlFor={`from-${index}`}>From</label>
                        <div className="input-icon-container">
                          <input
                            type="text"
                            id={`from-${index}`}
                            value={flight.from}
                            onChange={e =>
                              handleFlightChange(index, 'from', e.target.value)
                            }
                            required
                          />
                          <FaPlaneDeparture className="input-icon" />
                        </div>
                        <div
                          className="suggested-cities-container flex-column"
                          ref={suggestionsRef}>
                          {suggestedCities.from.map((city, cityIndex) => (
                            <div
                              key={cityIndex}
                              onClick={() =>
                                handleSuggestionClick(
                                  index,
                                  'from',
                                  city.attributes.cities_name,
                                )
                              }
                              className="suggested-city">
                              {city.attributes.cities_name}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex-column to">
                        <label htmlFor={`to-${index}`}>To</label>
                        <div className="input-icon-container">
                          <input
                            type="text"
                            id={`to-${index}`}
                            value={flight.to}
                            onChange={e =>
                              handleFlightChange(index, 'to', e.target.value)
                            }
                            required
                          />
                          <FaPlaneArrival className="input-icon" />
                        </div>
                        <div
                          className="suggested-cities-container flex-column"
                          ref={suggestionsRef}>
                          {suggestedCities.to.map((city, cityIndex) => (
                            <div
                              key={cityIndex}
                              onClick={() =>
                                handleSuggestionClick(
                                  index,
                                  'to',
                                  city.attributes.cities_name,
                                )
                              }
                              className="suggested-city">
                              {city.attributes.cities_name}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="flex-column">
                        <label htmlFor={`cabinClass}`}>Cabin Class</label>
                        <select
                          id={`cabinClass`}
                          value={flight.cabinClass}
                          onChange={e =>
                            handleFlightChange(
                              index,
                              'cabinClass',
                              e.target.value,
                            )
                          }>
                          <option value="economy">Economy</option>
                          <option value="premium-economy">
                            Premium Economy
                          </option>
                          <option value="business">Business</option>
                        </select>
                      </div>
                    </div>
                    {/*  */}
                    <div className="flight-duration flex-row gap-2">
                      <div className="flex-column">
                        <label htmlFor={`departure-${index}`}>Departure</label>
                        <input
                          type="date"
                          id={`departure-${index}`}
                          value={flight.departure}
                          onChange={e =>
                            handleFlightChange(
                              index,
                              'departure',
                              e.target.value,
                            )
                          }
                          required
                        />
                      </div>
                      {state.tripType !== 'one-way' && (
                        <div className="flex-column">
                          <label htmlFor={`return-${index}`}>Return</label>
                          <input
                            type="date"
                            id={`return-${index}`}
                            value={flight.returnDate || ''}
                            onChange={e =>
                              handleFlightChange(
                                index,
                                'returnDate',
                                e.target.value,
                              )
                            }
                            required
                          />
                        </div>
                      )}
                    </div>
                    <div className="flight-persons flex-row gap-2">
                      <div className="flex-column">
                        <label htmlFor={`adults-${index}`}>Adults</label>
                        <input
                          type="number"
                          id={`adults-${index}`}
                          value={flight.adults}
                          onChange={e =>
                            handleFlightChange(index, 'adults', e.target.value)
                          }
                          required
                        />
                      </div>
                      <div className="flex-column">
                        <label htmlFor={`children-${index}`}>Children</label>
                        <input
                          type="number"
                          id={`children-${index}`}
                          value={flight.children}
                          onChange={e =>
                            handleFlightChange(
                              index,
                              'children',
                              e.target.value,
                            )
                          }
                          required
                        />
                      </div>
                      <div className="flex-column">
                        <label htmlFor={`infants-${index}`}>Infants</label>
                        <input
                          type="number"
                          id={`infants-${index}`}
                          value={flight.infants}
                          onChange={e =>
                            handleFlightChange(index, 'infants', e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  {state.tripType === 'multi-city' && (
                    <div key={index + 1} className="flex-column gap-2">
                      <div
                        key={index + 1}
                        className="flight-route flex-row gap-2">
                        <div className="flex-column">
                          {state.tripType === 'multi-city' && <p>2st LEG</p>}
                          <label htmlFor={`from-${index + 1}`}>From</label>
                          <div className="input-icon-container">
                            <input
                              type="text"
                              id={`from-${index + 1 + 1}`}
                              value={flight.from}
                              onChange={e =>
                                handleFlightChange(
                                  index + 1,
                                  'from',
                                  e.target.value,
                                )
                              }
                              required
                            />
                            <FaPlaneDeparture className="input-icon" />
                          </div>
                        </div>
                        <div className="flex-column">
                          <label htmlFor={`to-${index + 1}`}>To</label>
                          <div className="input-icon-container">
                            <input
                              type="text"
                              id={`to-${index + 1}`}
                              value={flight.to}
                              onChange={e =>
                                handleFlightChange(
                                  index + 1,
                                  'to',
                                  e.target.value,
                                )
                              }
                              required
                            />
                            <FaPlaneArrival className="input-icon" />
                          </div>
                        </div>
                        <div className="flex-column">
                          <label htmlFor={`cabinClass}`}>Cabin Class</label>
                          <select
                            id={`cabinClass`}
                            value={flight.cabinClass}
                            onChange={e =>
                              handleFlightChange(
                                index + 1,
                                'cabinClass',
                                e.target.value,
                              )
                            }>
                            <option value="economy">Economy</option>
                            <option value="premium-economy">
                              Premium Economy
                            </option>
                            <option value="business">Business</option>
                          </select>
                        </div>
                      </div>
                      {/*  */}
                      <div className="flight-duration flex-row gap-2">
                        <div className="flex-column">
                          <label htmlFor={`departure-${index + 1}`}>
                            Departure
                          </label>
                          <input
                            type="date"
                            id={`departure-${index + 1}`}
                            value={flight.departure}
                            onChange={e =>
                              handleFlightChange(
                                index + 1,
                                'departure',
                                e.target.value,
                              )
                            }
                          />
                        </div>

                        <div className="flex-column">
                          <label htmlFor={`return-${index + 1}`}>Return</label>
                          <input
                            type="date"
                            id={`return-${index + 1}`}
                            value={flight.returnDate || ''}
                            onChange={e =>
                              handleFlightChange(
                                index + 1,
                                'returnDate',
                                e.target.value,
                              )
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="flight-persons flex-row gap-2">
                        <div className="flex-column">
                          <label htmlFor={`adults-${index + 1}`}>Adults</label>
                          <input
                            type="number"
                            id={`adults-${index + 1}`}
                            value={flight.adults}
                            onChange={e =>
                              handleFlightChange(
                                index + 1,
                                'adults',
                                e.target.value,
                              )
                            }
                            required
                          />
                        </div>
                        <div className="flex-column">
                          <label htmlFor={`children-${index + 1}`}>
                            Children
                          </label>
                          <input
                            type="number"
                            id={`children-${index + 1}`}
                            value={flight.children}
                            onChange={e =>
                              handleFlightChange(
                                index + 1,
                                'children',
                                e.target.value,
                              )
                            }
                            required
                          />
                        </div>
                        <div className="flex-column">
                          <label htmlFor={`infants-${index + 1}`}>
                            Infants
                          </label>
                          <input
                            type="number"
                            id={`infants-${index + 1}`}
                            value={flight.infants}
                            onChange={e =>
                              handleFlightChange(
                                index + 1,
                                'infants',
                                e.target.value,
                              )
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              {/* 
              {state.tripType === 'multi-city' && (
                
              )} */}

              <div className="flight-user-details flex-row">
                <div className="flex-column">
                  <label htmlFor="name">Name*</label>
                  <input
                    type="text"
                    id="name"
                    value={state.name}
                    onChange={e => handleFieldChange(e)}
                    required
                  />
                </div>
                <div className="flex-column">
                  <label htmlFor="email">Email*</label>
                  <input
                    type="text"
                    id="email"
                    value={state.email}
                    onChange={e => handleFieldChange(e)}
                    required
                  />
                </div>
                <div className="flex-column">
                  <label htmlFor="phone">Phone*</label>
                  <input
                    type="text"
                    id="phone"
                    value={state.phone}
                    onChange={e => handleFieldChange(e)}
                    required
                  />
                </div>
              </div>

              <button type="submit">Search</button>
            </form>
          ) : (
            <form
              action="submit"
              className="header-form"
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                navigate('/request-callback');
              }}>
              <div className="trip-details">
                <input type="radio" id="work" name="flight-details" />
                <label htmlFor="work">Work</label>
                <input type="radio" id="Vacation" name="flight-details" />
                <label htmlFor="Vacation">Vacation</label>
              </div>
              <div className="flex-row gap-2 flight-route">
                <div className="flex-column">
                  <label htmlFor="destination">Destinations*</label>
                  <input type="text" id="destination" required />
                </div>
                <div className="flex-column">
                  <label htmlFor="rooms">Rooms*</label>
                  <input type="number" id="rooms" required />
                </div>
                <div className="flex-column">
                  <label htmlFor="to">Star rating</label>
                  <input type="text" id="to" />
                </div>
              </div>
              <div className="flight-duration flex-row">
                <div className="flex-column">
                  <label htmlFor="departure">Departure</label>
                  <input type="date" id="departure" required />
                </div>
                <div className="flex-column">
                  <label htmlFor="return">Return</label>
                  <input type="date" id="return" required />
                </div>
              </div>
              <div className="flight-persons flex-row">
                <div className="flex-column">
                  <label htmlFor="adult">Adult(12+)</label>
                  <input type="text" id="adult" required />
                </div>
                <div className="flex-column">
                  <label htmlFor="child">Child(2-12)</label>
                  <input type="text" id="child" required />
                </div>
                <div className="flex-column">
                  <label htmlFor="infant">Infant(&lt;2)</label>
                  <input type="text" id="infant" required />
                </div>
              </div>
              <div className="flight-user-details flex-row">
                <div className="flex-column">
                  <label htmlFor="name">Name*</label>
                  <input type="text" id="name" required />
                </div>
                <div className="flex-column">
                  <label htmlFor="email">Email*</label>
                  <input type="text" id="email" required />
                </div>
                <div className="flex-column">
                  <label htmlFor="phone">Phone*</label>
                  <input type="text" id="phone" required />
                </div>
                <button type="submit">Search</button>
              </div>
            </form>
          )}
        </section>
        {countryData.length && (
          <TicketSection
            flag={countryData[currentIndex]?.flag}
            countryName={countryData[currentIndex]?.country}
            cities={countryData[currentIndex]?.country_flight_cities}
          />
        )}
      </div>
    </header>
  );
}

function TicketSection(_props: {
  flag: string;
  countryName: string;
  cities: {city: string; price: string}[];
}) {
  return (
    <div className="header-tickets-section">
      <div className="ticket-country-name">
        <img src={_props.flag} alt={_props.countryName} />
        <p>{_props.countryName}</p>
      </div>
      <div className="header-ticket-container">
        {_props.cities.map((cityData, index) => (
          <Ticket key={index} city={cityData.city} price={cityData.price} />
        ))}
      </div>
    </div>
  );
}

function Ticket(_props: {city: string; price: string}) {
  const navigate = useNavigate();
  return (
    <div className="header-ticket">
      <div className="ticket-city flex-row">
        <div className="ticket-city-name flex-row">
          <FaPlane />
          <p>{_props.city}</p>
        </div>
        <div className="flex-row">
          <div className="ticket-divider"></div>
          <span className="ticket-price-text">Price*</span>
          <p className="ticket-price">£{_props.price}</p>
        </div>
      </div>
      <div className="ticket-callback flex-row">
        <button
          className="select-ticket"
          onClick={() => {
            navigate(`/flight/${_props.city}`);
          }}>
          select
        </button>
        <a
          className="request-ticket flex-row"
          onClick={() => {
            navigate('/request-callback');
          }}>
          <FaPhoneAlt />
          <p>Request a call back</p>
        </a>
      </div>
    </div>
  );
}
